import "react-app-polyfill/stable"
import "core-js"
import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import { Provider } from "react-redux"
import { UserContextProvider } from "./context/UserContext"
// import "primereact/resources/themes/lara-light-indigo/theme.css" //theme
import { CssBaseline } from "@mui/material"
import "./theme/theme.css"
//import "./theme/theme-dark.css"
import "primereact/resources/primereact.min.css" //core css
import "primeicons/primeicons.css"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"
import "./index.css"
import "animate.css"
import { RouterContextProvider } from "./context/RouterContext"
import { AnimationContextProvider } from "./context/AnimationContext"
import { store } from "./store/store"

createRoot(document.getElementById("root")).render(
  <UserContextProvider>
    <RouterContextProvider>
      <AnimationContextProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <CssBaseline />
            <App />
          </I18nextProvider>
        </Provider>
      </AnimationContextProvider>
    </RouterContextProvider>
  </UserContextProvider>
)
