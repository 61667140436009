import React from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
} from "@coreui/react"
import CIcon from "@coreui/icons-react"
import { cilMenu } from "@coreui/icons"
import { AppBreadcrumb } from "./index"
import { AppHeaderDropdown, AppHeaderDropdownLanguage } from "./header/index"
import { sidebarActions } from "src/store/features/sidebar/sidebarSlice"
//import AppHeaderToggleTheme from "./header/AppHeaderToggleTheme"

const AppHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebar.sidebarShow)
  return (
    <CHeader>
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch(sidebarActions.show(!sidebarShow))}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/dashboard">
          <div style={{ fontSize: "xx-large" }}>
            GC <strong>Win</strong>
          </div>
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto ">
          <CNavItem>
            <AppBreadcrumb />
          </CNavItem>
        </CHeaderNav>
        {/* <CHeaderNav className="ms-3">
          <AppHeaderToggleTheme />
        </CHeaderNav> */}
        <CHeaderNav className="ms-3">
          <AppHeaderDropdownLanguage />
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
