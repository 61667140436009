import { createContext, useState } from "react"

export const AnimationContext = createContext({
  animationClassname: "",
  handleUpdateAnimationClassname: (animationClassname) => {},
})

export const AnimationContextProvider = (props) => {
  const [animationClassname, setAnimationClassname] = useState("")

  const handleUpdateAnimationClassname = (animationClassname) => {
    setAnimationClassname(animationClassname)
  }

  const contextValue = {
    animationClassname: animationClassname,
    handleUpdateAnimationClassname: handleUpdateAnimationClassname,
  }

  return (
    <AnimationContext.Provider value={contextValue}>
      {props.children}
    </AnimationContext.Provider>
  )
}
