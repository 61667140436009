import { Avatar } from "@mui/material"
import { useContext } from "react"
import { UserContext } from "src/context/UserContext"

const AvatarInitial = (props) => {
  //On récupère les données utilisateur dans le store contexte ainsi que la fonction de déconnexion
  const { firstnameContext, lastnameContext } = useContext(UserContext)

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#3c4b64",
        fontSize: "1rem",
        width: "2rem",
        height: "2rem",
        ...props.style,
      },
      children: `${name.split(" ")[0][0].toUpperCase()}${name
        .split(" ")[1][0]
        .toUpperCase()}`,
    }
  }

  return (
    <Avatar
      className={props.className}
      {...stringAvatar(`${firstnameContext} ${lastnameContext}`)}
    />
  )
}

export default AvatarInitial
