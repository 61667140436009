import jwtDecode from "jwt-decode"
import { redirect } from "react-router-dom"

// export const getIsLoggedIn = () => {
//   return sessionStorage.getItem("isLoggedIn")
// }

// export const setIsLoggedIn = (value) => {
//   sessionStorage.setItem("isLoggedIn", value)
// }

//Retourne la valeur du JWT dans le session storage
export const getJwt = () => {
  return sessionStorage.getItem("JWT")
}

//Modifie la valeur du JWT dans le session storage
export const setJwt = (jwt) => {
  sessionStorage.setItem("JWT", jwt)
}

//Retourne la valeur du user dans le session storage
export const getUser = () => {
  return sessionStorage.getItem("user")
}

//Modifie la valeur du user dans le session storage
export const setUser = (user) => {
  sessionStorage.setItem("user", user)
}

//Retourne l'ID de l'utilisateur dans le session storage
export const getId = () => {
  if (getUser()) {
    return JSON.parse(sessionStorage.user).id
  } else {
    return null
  }
}

//Retourne le prénom de l'utilisateur dans le session storage
export const getFirstname = () => {
  if (getUser()) {
    return JSON.parse(sessionStorage.user).firstname
  } else {
    return null
  }
}

//Modifie le prénom de l'utilisateur dans le session storage
export const setFirstname = (firstname) => {
  if (getUser()) {
    const user = JSON.parse(sessionStorage.user)
    user.firstname = firstname
    sessionStorage.setItem("user", JSON.stringify(user))
  }
}

//Retourne le nom de l'utilisateur dans le session storage
export const getLastname = () => {
  if (getUser()) {
    return JSON.parse(sessionStorage.user).lastname
  } else {
    return null
  }
}

//Modifie le nom de l'utilisateur dans le session storage
export const setLastname = (lastname) => {
  if (getUser()) {
    const user = JSON.parse(sessionStorage.user)
    user.lastname = lastname
    sessionStorage.setItem("user", JSON.stringify(user))
  }
}

//Récupération des roles de l'utilisateur authentifié
export const getUserRoles = () => {
  const token = getJwt()
  if (token) {
    //décodage du jwt
    const userdatas = jwtDecode(token)
    return userdatas.roles
  } else return null
}

//Fonction qui retourne la date d'expiration du jwt
export const getExpDate = () => {
  const jwt = getJwt()
  if (typeof jwt === "string") {
    return jwtDecode(jwt).exp
  } else return null
}

/**
 * Fonction permettant de vérifier si un utilisateur a le role admin
 * @returns boolean
 */
export const userHaveAdminRole = () => {
  const token = getJwt()

  if (token) {
    const userRoles = jwtDecode(token).roles
    if (userRoles.length > 0) {
      if (userRoles.includes("ROLE_ADMIN")) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

//Vider le sessionStorage
//en conservant la langue
export const removeStorage = () => {
  const lng = sessionStorage.getItem("i18nextLng")
  sessionStorage.clear()
  if (lng) {
    sessionStorage.setItem("i18nextLng", lng)
  }
}

/**
 * Fonction permettant de rediriger un utilisateur authentifié
 * @returns redirection ou null
 */
export function redirectIfUserIsLogged() {
  //const isLoggedIn = getIsLoggedIn()
  //if (isLoggedIn === "true") {
  const token = getJwt()

  //Si l'utilisateur vérifie son email
  if (window.location.pathname.includes("/email/verify")) {
    return null
  }

  //Si l'utilisateur est authentifié, redirection sur la page de match
  if (token) {
    const userdatas = jwtDecode(token)
    //const nbaRoles = ["ROLE_NBA", "ROLE_ADMIN"]
    if (userdatas.roles.length === 1 && userdatas.roles[0] === "ROLE_USER") {
      return redirect("/profile")
    } else return redirect("/dashboard")
    // if (userdatas.roles.some((role) => nbaRoles.includes(role)))
    //   return redirect("/basketball/nba/matchs")
    // else return redirect("/profile")
  }
  //}
  //la fonction doit avoir une valeur de retour si le token est présent. Ici nous n'avons pas besoin de valeur spécifique, le retour est null
  return null
}

/**
 * Fonction permettant de récupérer le role d'un utilisateur connecté et stocké dans le jwt du storage et de vérifier qu'il est admin
 * @returns boolean
 */
export const isUserIsAdmin = () => {
  const token = getJwt()
  if (token) {
    //décodage du jwt
    const userdatas = jwtDecode(token)
    const roles = userdatas.roles
    //vérification du role
    if (roles.find((role) => role === "ROLE_ADMIN") !== undefined) {
      //si le role est admin
      return true
    } else {
      //si le role admin n'existe pas
      return false
    }
  } else {
    //s'il n'y a pas d'utilisateur
    return false
  }
}

/**
 * Fonction permettant de vérifier si un utilisateur a le role standardization
 * @returns boolean
 */
export const userHaveStdRole = () => {
  const token = getJwt()

  if (token) {
    const userRoles = jwtDecode(token).roles
    if (userRoles.length > 0) {
      if (userRoles.includes("ROLE_NBA_STANDARDIZATION")) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

//Retourne la version du dashboard dans le session storage
export const getDashboardVersion = () => {
  return sessionStorage.getItem("dashboardVersion")
}

//Stocke la version du dashboard dans le session storage
export const setDashboardVersion = (dashboardVersion) => {
  return sessionStorage.setItem("dashboardVersion", dashboardVersion)
}

//Retourne si le mode dark est activé dans le session storage
export const getDarkModeEnabled = () => {
  return sessionStorage.getItem("darkModeEnabled") === "true" ? true : false
  // return sessionStorage.getItem("darkModeEnabled")
}

//Stocke si le mode dark est activé dans le session storage
export const setDarkModeEnabled = (darkMode) => {
  return sessionStorage.setItem("darkModeEnabled", darkMode)
}

//Retourne la time zone choisie
export const getTimeZone = () => {
  return sessionStorage.getItem("timeZone")
  // return sessionStorage.getItem("darkModeEnabled")
}

////Stocke la time zone choisie
export const setTimeZone = (timeZone) => {
  return sessionStorage.setItem("timeZone", timeZone)
}
