import React from "react"
import {
  CAvatar,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react"
import { withTranslation } from "react-i18next"
import { setLanguage } from "src/store/features/language/languageSlice"
import fr from "src/assets/images/logo/fr.png"
import en from "src/assets/images/logo/uk.png"
import notFound from "src/assets/images/logo/notFound.png"
import { useDispatch, useSelector } from "react-redux"
import style from "./AppHeaderDropdown.module.css"
import { backdropShowActions } from "src/store/features/backdrop/backdropShowSlice"
import i18n from "src/i18n"

const languagesProps = {
  fr: { image: fr, label: "Français" },
  en: { image: en, label: "English" },
  // Ajoutez d'autres langues ici avec leurs chemins d'accès aux images
}

const AppHeaderDropdownLanguage = () => {
  //Ajout d'un effet de blur au clique sur le dropdown en récupérant l'état du backdrop dans le store
  const dispatch = useDispatch()
  const backdropShow = useSelector((state) => state.backdropShow.backdropShow) //store du backdrop
  const language = useSelector((state) => state.language.language) // store du langage

  const handleChangeLanguage = (lng) => {
    i18n.changeLanguage(lng)
    dispatch(setLanguage(lng))
  }

  return (
    <CDropdown
      onClick={() => dispatch(backdropShowActions.show(!backdropShow))}
      variant="nav-item"
    >
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar
          shape="rounded-0"
          src={
            languagesProps[language]?.image
              ? languagesProps[language].image
              : notFound
          }
          className={`${style.shadow} avatar-xs`}
        />
      </CDropdownToggle>
      <CDropdownMenu
        className="dropdown-menu position-blur"
        placement="bottom-end"
      >
        {languagesProps ? (
          Object.keys(languagesProps)
            ?.filter((l) => l !== language)
            .map((lang) => {
              return (
                <CDropdownItem
                  key={lang}
                  aria-label={lang}
                  className={style.cursorLink}
                  onClick={() => {
                    setTimeout(() => {
                      handleChangeLanguage(lang)
                    }, 200)
                  }}
                  data-testid={lang}
                >
                  <img
                    height={48}
                    width={48}
                    src={languagesProps[lang].image}
                    alt={lang}
                    className="me-2 avatar-xs"
                    // size="sm"
                    // shape="rounded-0"
                  />
                  {languagesProps[lang].label}
                </CDropdownItem>
              )
            })
        ) : (
          <></>
        )}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default withTranslation()(AppHeaderDropdownLanguage)
