import React from "react"
import { Suspense } from "react"
import LoaderSecondary from "../../components/loaders/LoaderSecondary"
//import DefaultLayout from "src/layout/protected/DefaultLayout"
import { basketballRoutes } from "./basketballRoutes/basketballRoutes"
import { cyclingRoutes } from "./cyclingRoutes/cyclingRoutes"
import CIcon from "@coreui/icons-react"
import { cilUser } from "@coreui/icons"
import dashboardLogo from "src/assets/images/logo/dashboard.png"

//Profile page
const Profile = React.lazy(() => import("../../views/general/profile/Profile"))

//Setting page
const Settings = React.lazy(() =>
  import("../../views/general/settings/Settings")
)

//Admin page
const Users = React.lazy(() => import("../../views/admin/Users"))

//dashboard
const Dashboard = React.lazy(() => import("src/views/dashboard/Dashboard"))
//const DashboardV1 = React.lazy(() => import("src/views/dashboard/DashboardV1"))
//const DashboardV2 = React.lazy(() => import("src/views/dashboard/DashboardV2"))

export const routes = [
  // publicRoutes,
  // {
  //   name: "Layout",
  //   path: "/",
  //   state: "Layout",
  //   element: (
  //     //      <Suspense fallback={<LoaderSecondary />}>
  //     <DefaultLayout />
  //     //    </Suspense>
  //   ),
  //   permissions: "ROLE_USER",
  //   children:
  //[
  // {
  //   name: "ErrorPage",
  //   path: "*",
  //   element: <ErrorPage />,
  // },
  {
    name: "Profile",
    path: "/profile",
    state: "profile",
    element: (
      <Suspense fallback={<LoaderSecondary />}>
        <Profile />
      </Suspense>
    ),
    permissions: "ROLE_USER",
  },
  {
    name: "Settings",
    path: "/settings",
    state: "settings",
    element: (
      <Suspense fallback={<LoaderSecondary />}>
        <Settings />
      </Suspense>
    ),
    permissions: "ROLE_USER",
  },
  {
    name: "Dashboard",
    index: true,
    state: "dashboard",
    element: <Dashboard />,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    state: "dashboard",
    sidebarProps: {
      displayText: "dashboard",
      icon: (
        <img
          src={dashboardLogo}
          alt="Dashboard logo"
          height={25}
          width={25}
          style={{ marginRight: "5px" }}
        />
      ),
      style: "menu1",
    },
    element: <Dashboard />,
    //loader: loaderProfile,
    permissions: "ROLE_USER",
  },
  // {
  //   name: "DashboardV1",
  //   path: "/dashboardV1",
  //   state: "dashboardV1",
  //   sidebarProps: {
  //     displayText: "dashboardV1",
  //     icon: (
  //       <img
  //         src={dashboardLogo}
  //         alt="Dashboard logo"
  //         height={25}
  //         width={25}
  //         style={{ marginRight: "5px" }}
  //       />
  //     ),
  //     style: "menu1",
  //   },
  //   element: <DashboardV1 />,
  //   //loader: loaderProfile,
  //   permissions: "ROLE_USER",
  // },
  // {
  //   name: "DashboardV2",
  //   path: "/dashboardV2",
  //   state: "dashboardV2",
  //   sidebarProps: {
  //     displayText: "dashboardV2",
  //     icon: (
  //       <img
  //         src={dashboardLogo}
  //         alt="Dashboard logo"
  //         height={25}
  //         width={25}
  //         style={{ marginRight: "5px" }}
  //       />
  //     ),
  //     style: "menu1",
  //   },
  //   element: <DashboardV2 />,
  //   //loader: loaderProfile,
  //   permissions: "ROLE_USER",
  // },
  {
    name: "Sport",
    sidebarProps: {
      displayText: "sport",
      mainMenu: true,
    },
  },
  basketballRoutes,
  cyclingRoutes,
  {
    name: "Admin",
    sidebarProps: {
      displayText: "admin",
      mainMenu: true,
    },
    permissions: "ROLE_ADMIN",
  },
  // {
  //   name: "Admin",
  //   path: "/admin",
  //   state: "admin",
  //   sidebarProps: {
  //     displayText: "Admin",
  //   },
  //   element: <PageLayout />,
  //   permissions: "ROLE_ADMIN",
  //   children: [
  {
    name: "Users",
    path: "/admin/usersList",
    state: "admin.users",
    sidebarProps: {
      displayText: "users",
      icon: (
        <CIcon
          icon={cilUser}
          height={25}
          width={25}
          style={{ marginRight: "5px" }}
        />
      ),
    },
    element: (
      <Suspense fallback={<LoaderSecondary />}>
        <Users />
      </Suspense>
    ),
    permissions: "ROLE_ADMIN",
    //   },
    // ],
  },
]
//   },
// ]
