import { ProgressSpinner } from "primereact/progressspinner"

const Loader = (props) => {
  return (
    <div
      style={{
        minHeight: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
    >
      <div className="text-center d-flex h-100">
        <ProgressSpinner
          animationDuration="1s"
          className="mx-1 main-spinner"
          style={{ width: "40px", height: "40px" }}
          strokeWidth="5"
        />
      </div>
    </div>
  )
}

export default Loader
