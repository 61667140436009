import { Route } from "react-router-dom"
import PageWrapper from "src/layout/protected/PageWrapper"

export const generateRoute = (routes) => {
  if (routes) {
    return routes.map(
      (route, index) =>
        route.index ? (
          <Route
            index
            path={route.path}
            element={
              <PageWrapper state={route.state}>{route.element}</PageWrapper>
            }
            key={index}
          />
        ) : (
          <Route
            path={route.path}
            element={
              <PageWrapper state={route.children ? undefined : route.state}>
                {route.element}
              </PageWrapper>
            }
            key={index}
          >
            {route.children && generateRoute(route.children)}
          </Route>
        )
      //}
    )
  }
}
