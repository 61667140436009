import React from "react"
import PageLayout from "../PagesLayout"
import CBasketball from "src/assets/images/logo/basketball.ico"
//import fibaWorldCup from "src/assets/images/logo/fibaWorldCup.png"
import { nbaRoutes } from "./nbaRoutes/nbaRoutes"
//import LoaderSecondary from "src/components/loaders/LoaderSecondary"
import { fibaRoutes } from "./fibaRoutes/fibaRoutes"

// const NotAvailableEvent = React.lazy(() =>
//   import("src/views/general/notAvailableEvent/NotAvailableEvent")
// )

export const basketballRoutes = {
  name: "Basketball",
  path: "/basketball",
  state: "basketball",
  permissions: [
    "ROLE_NBA",
    "ROLE_NBA_STANDARDIZATION",
    "ROLE_NBA_MANAGE_DISCREPANCIES",
    "ROLE_NBA_STAGING_DATA",
    "ROLE_FIBAWC",
    "ROLE_FIBAWC_STANDARDIZATION",
    "ROLE_FIBAWC_MANAGE_DISCREPANCIES",
    "ROLE_FIBAWC_STAGING_DATA",
  ],
  element: <PageLayout />,
  sidebarProps: {
    displayText: "basketball",
    icon: (
      <img
        src={CBasketball}
        alt="basket Logo"
        height={25}
        width={25}
        style={{ marginRight: "5px" }}
      />
    ),
    style: "menu1",
  },
  children: [
    nbaRoutes,
    //Décommenter en enlever objet suivant pour afficher les routes du tde
    fibaRoutes,
    // {
    //   name: "FIBA world cup",
    //   path: "/basketball/fibawc",
    //   state: "basketball.fibawc",
    //   sidebarProps: {
    //     displayText: "fibaWorldCup",
    //     icon: (
    //       <img
    //         src={fibaWorldCup}
    //         alt="FIBA Logo"
    //         height={25}
    //         width={25}
    //         style={{ marginRight: "5px" }}
    //       />
    //     ),
    //     style: "menu2",
    //   },
    //   element: (
    //     <Suspense fallback={<LoaderSecondary />}>
    //       <NotAvailableEvent />
    //     </Suspense>
    //   ),
    //   permissions: ["ROLE_FIBA"],
    // },
  ],
}
