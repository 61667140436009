import React from "react"
import { Suspense } from "react"
import LoaderSecondary from "src/components/loaders/LoaderSecondary"
import PageLayout from "../../PagesLayout"
import TDELogo from "src/assets/images/logo/TDE.png"

//Biking general
const TDEGeneralWinners = React.lazy(() =>
  import("src/views/cycling/vuelta/general/winners/Winners")
)
// const vueltaGeneralDoubleChanceWinner = React.lazy(() =>
//   import("src/views/cycling/vuelta/general/doubleChanceWinner/DoubleChanceWinner")
// )
const TDEGeneralPodium = React.lazy(() =>
  import("src/views/cycling/vuelta/general/podium/Podium")
)
// const vueltaGeneralDoubleChancePodium = React.lazy(() =>
//   import("src/views/cycling/vuelta/general/doubleChancePodium/DoubleChancePodium")
// )

//biking étape
const TDEStages = React.lazy(() =>
  import(
    "src/views/cycling/vuelta/stages/stagesInformations/StagesInformations"
  )
)
// const vueltaStepWinner = React.lazy(() =>
//   import("src/views/cycling/vuelta/stages/winner/Winner")
// )
// const vueltaStepDoubleChanceWinner = React.lazy(() =>
//   import("src/views/cycling/vuelta/stages/doubleChanceWinner/DoubleChanceWinner")
// )
// const vueltaStepPodium = React.lazy(() =>
//   import("src/views/cycling/vuelta/stages/podium/Podium")
// )
// const vueltaStepDoubleChancePodium = React.lazy(() =>
//   import("src/views/cycling/vuelta/stages/doubleChancePodium/DoubleChancePodium")
// )

//biking classement
const TDERankBestYoung = React.lazy(() =>
  import("src/views/cycling/vuelta/rank/bestYoungRanking/BestYoungRanking")
)
const TDERankClimber = React.lazy(() =>
  import("src/views/cycling/vuelta/rank/climberRanking/ClimberRanking")
)
const TDERankPoints = React.lazy(() =>
  import("src/views/cycling/vuelta/rank/pointsRanking/PointsRanking")
)
const TDERankTeams = React.lazy(() =>
  import("src/views/cycling/vuelta/rank/teamRanking/TeamRanking")
)

const TeamsBikingStandardization = React.lazy(() =>
  import("src/views/cycling/vuelta/standardization/Teams")
)
const RunnersBikingStandardization = React.lazy(() =>
  import("src/views/cycling/vuelta/standardization/Runners")
)

//Biking settings pages
const DiscrepanciesBiking = React.lazy(() =>
  import("src/views/cycling/vuelta/settings/discrepancies/Discrepancies")
)

const ScrapingBiking = React.lazy(() =>
  import("src/views/cycling/vuelta/settings/scraping/Scraping")
)

const StandardizationBiking = React.lazy(() =>
  import("src/views/cycling/vuelta/settings/standardization/Standardization")
)

export const vueltaRoutes = {
  name: "TDE",
  path: "/cycling/vuelta",
  state: "cycling.vuelta",
  sidebarProps: {
    displayText: "vuelta",
    icon: (
      <img
        src={TDELogo}
        alt="Tour d'Espagne logo"
        height={25}
        width={25}
        style={{ marginRight: "5px" }}
      />
    ),
    style: "menu2",
  },
  element: <PageLayout />,
  permissions: ["ROLE_VUELTA"],
  children: [
    {
      name: "TDEStages",
      path: "/cycling/vuelta/stagesvuelta",
      state: "cycling.vuelta.stagesvuelta",
      sidebarProps: {
        displayText: "stagesInf",
        style: "menu3",
      },
      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <TDEStages />
        </Suspense>
      ),
      permissions: ["ROLE_VUELTA"],
    },
    {
      name: "general",
      path: "/cycling/vuelta/general",
      state: "cycling.vuelta/general",
      sidebarProps: {
        displayText: "general",
        style: "menu3",
      },
      element: <PageLayout />,
      children: [
        {
          name: "vueltaGeneralWinner",
          path: "/cycling/vuelta/general/winners",
          state: "cycling.vuelta/general.winners",
          sidebarProps: {
            displayText: "winner",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TDEGeneralWinners />
            </Suspense>
          ),
          permissions: ["ROLE_VUELTA"],
        },
        // {
        //   name: "vueltaGeneralDoubleChanceWinner",
        //   path: "/cycling/vuelta/general/winnersdc",
        //   state: "cycling.vuelta/general.winnersdc",
        //   sidebarProps: {
        //     displayText: "winnersdc",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <vueltaGeneralDoubleChanceWinner />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_VUELTA"],
        // },
        {
          name: "vueltaGeneralPodium",
          path: "/cycling/vuelta/general/podium",
          state: "cycling.vuelta/general.podium",
          sidebarProps: {
            displayText: "podium",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TDEGeneralPodium />
            </Suspense>
          ),
          permissions: ["ROLE_VUELTA"],
        },
        // {
        //   name: "vueltaGeneralDoubleChancePodium",
        //   path: "/cycling/vuelta/general/podiumdc",
        //   state: "cycling.vuelta/general.podiumdc",
        //   sidebarProps: {
        //     displayText: "podiumdc",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <vueltaGeneralDoubleChancePodium />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_VUELTA"],
        // },
      ],
    },
    {
      name: "stages",
      path: "/cycling/vuelta/stages",
      state: "cycling.vuelta/stages",
      sidebarProps: {
        displayText: "stages",
        style: "menu3",
      },
      element: <PageLayout />,
      children: [
        // {
        //   name: "vueltaStepWinner",
        //   path: "/cycling/vuelta/stages/winners",
        //   state: "cycling.vuelta/stages.winners",
        //   sidebarProps: {
        //     displayText: "winners",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <vueltaStepWinner />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_VUELTA"],
        // },
        // {
        //   name: "vueltaStepDoubleChanceWinner",
        //   path: "/cycling/vuelta/stages/winnersdc",
        //   state: "cycling.vuelta/stages.winnersdc",
        //   sidebarProps: {
        //     displayText: "doubleChanceWinner",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <vueltaStepDoubleChanceWinner />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_VUELTA"],
        // },
        // {
        //   name: "vueltaStepPodium",
        //   path: "/cycling/vuelta/stages/podium",
        //   state: "cycling.vuelta/stages.podium",
        //   sidebarProps: {
        //     displayText: "podium",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <vueltaStepPodium />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_VUELTA"],
        // },
        // {
        //   name: "vueltaStepDoubleChancePodium",
        //   path: "/cycling/vuelta/stages/podiumdc",
        //   state: "cycling.vuelta/stages.podiumdc",
        //   sidebarProps: {
        //     displayText: "doubleChancePodium",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <vueltaStepDoubleChancePodium />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_VUELTA"],
        // },
      ],
    },
    {
      name: "ranking",
      path: "/cycling/vuelta/ranking",
      state: "cycling.vuelta.ranking",
      sidebarProps: {
        displayText: "ranking",
        style: "menu3",
      },
      element: <PageLayout />,
      children: [
        {
          name: "vueltaRankBestYoung",
          path: "/cycling/vuelta/ranking/best-young",
          state: "cycling.vuelta.ranking.best-young",
          sidebarProps: {
            displayText: "bestYoung",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TDERankBestYoung />
            </Suspense>
          ),
          permissions: ["ROLE_VUELTA"],
        },
        {
          name: "vueltaRankClimber",
          path: "/cycling/vuelta/ranking/best-climber",
          state: "cycling.vuelta.ranking.best-climber",
          sidebarProps: {
            displayText: "bestClimber",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TDERankClimber />
            </Suspense>
          ),
          permissions: ["ROLE_VUELTA"],
        },
        {
          name: "vueltaRankPoints",
          path: "/cycling/vuelta/ranking/best-sprinter",
          state: "cycling.vuelta.ranking.best-sprinter",
          sidebarProps: {
            displayText: "bestSprinter",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TDERankPoints />
            </Suspense>
          ),
          permissions: ["ROLE_VUELTA"],
        },
        {
          name: "vueltaRankTeams",
          path: "/cycling/vuelta/ranking/best-team",
          state: "cycling.vuelta.ranking.best-team",
          sidebarProps: {
            displayText: "bestTeam",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TDERankTeams />
            </Suspense>
          ),
          permissions: ["ROLE_VUELTA"],
        },
      ],
    },
    {
      name: "Standardization",
      path: "/cycling/vuelta/standardization",
      state: "cycling.vuelta.standardization",
      sidebarProps: {
        displayText: "standardization",
        style: "menu3",
      },
      element: <PageLayout />,
      permissions: ["ROLE_VUELTA_STANDARDIZATION"],

      children: [
        {
          name: "Runners",
          path: "/cycling/vuelta/standardization/runners",
          state: "cycling.vuelta.standardization.runners",
          sidebarProps: {
            displayText: "runners",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <RunnersBikingStandardization />
            </Suspense>
          ),
          permissions: ["ROLE_VUELTA_STANDARDIZATION"],
        },
        {
          name: "Teams",
          path: "/cycling/vuelta/standardization/teams",
          state: "cycling.vuelta.standardization.teams",
          sidebarProps: {
            displayText: "teams",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TeamsBikingStandardization />
            </Suspense>
          ),
          permissions: ["ROLE_VUELTA_STANDARDIZATION"],
        },
      ],
    },
    {
      name: "Setting",
      path: "/cycling/vuelta/settings",
      state: "cycling.vuelta.settings",
      sidebarProps: {
        displayText: "settings",
        style: "menu3",
      },
      element: <PageLayout />,
      permissions: [
        "ROLE_VUELTA_STAGING_DATA",
        "ROLE_VUELTA_MANAGE_DISCREPANCIES",
        "ROLE_VUELTA_STANDARDIZATION",
      ],

      children: [
        {
          name: "Discrepancies",
          path: "/cycling/vuelta/settings/discrepancies",
          state: "cycling.vuelta.settings.discrepancies",
          sidebarProps: {
            displayText: "discrepancies",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <DiscrepanciesBiking />
            </Suspense>
          ),
          permissions: ["ROLE_VUELTA_MANAGE_DISCREPANCIES"],
        },
        {
          name: "Scraping",
          path: "/cycling/vuelta/settings/scraping",
          state: "cycling.vuelta.settings.scraping",
          sidebarProps: {
            displayText: "scraping",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <ScrapingBiking />
            </Suspense>
          ),
          permissions: ["ROLE_VUELTA_STAGING_DATA"],
        },
        {
          name: "Standardization",
          path: "/cycling/vuelta/settings/standardization",
          state: "cycling.vuelta.settings.standardization",
          sidebarProps: {
            displayText: "standardization",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <StandardizationBiking />
            </Suspense>
          ),
          permissions: ["ROLE_VUELTA_STANDARDIZATION"],
        },
      ],
    },
  ],
}
