import { useTranslation } from "react-i18next"

const SidebarItemCategory = ({ item }) => {
  const { t } = useTranslation(["routes"])
  return (
    <div
      style={{
        fontWeight: "900",
        textTransform: "uppercase",
        color: "hsla(0,0%,100%,.6)",
        paddingTop: "2rem",
        paddingBottom: "10px",
        marginLeft: "10px",
        fontSize: "100%",
      }}
    >
      {t(`routes.${item.sidebarProps.displayText}`)}
    </div>
  )
}

export default SidebarItemCategory
