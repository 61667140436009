import { ProgressSpinner } from "primereact/progressspinner"

const LoaderSecondary = () => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center text-center">
      <ProgressSpinner
        animationDuration="2s"
        className="mx-1 main-spinner"
        style={{ width: "35px", height: "35px" }}
        strokeWidth="4"
      />
    </div>
  )
}

export default LoaderSecondary
