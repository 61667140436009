import { configureStore } from "@reduxjs/toolkit"
import sidebarSlice from "./features/sidebar/sidebarSlice"
import languageSlice, { setLanguage } from "./features/language/languageSlice"
import backdropShowSlice from "./features/backdrop/backdropShowSlice"
import reloadComponentSlice from "./features/reloadComponent/reloadComponentSlice"
import appStateSlice from "./features/appState/appStateSlice"
import i18n from "src/i18n"
//import dashboardVersionSlice from "./features/dashboardVersion/dashboardVersionSlice"

export const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    language: languageSlice,
    backdropShow: backdropShowSlice,
    reloadComponent: reloadComponentSlice,
    appState: appStateSlice,
    // dashboardVersion: dashboardVersionSlice,
  },

  //C'est pour les routes..... ce n'est pas une variable serializable (elle contient du jsx)
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
})

// Écoutez l'événement 'loaded' de i18next
//dès que i18n a fini de s'initialiser on met à jour la langue
i18n.on("loaded", () => {
  store.dispatch(setLanguage(i18n.language.split("-")[0]))
})
