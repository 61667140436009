import { getUserRoles } from "../helpers"
import { publicRoutes } from "./publicRoutes/publicRoutes"

export function getAllowedRoutes(routes) {
  if (routes) {
    //Récupération de la liste des roles de l'utilisateur
    const roles = getUserRoles()
    if (roles) {
      //Si il y a le role admin retourne toutes les routes
      if (roles.includes("ROLE_ADMIN")) {
        return routes
      } else {
        const tmpRoutes = []
        routes.forEach((route) => {
          if (route.children) {
            if (!route.permissions) {
              const routeCpy = Object.assign({}, route)
              tmpRoutes.push(routeCpy)
              routeCpy.children = getAllowedRoutes(route.children)
            } else {
              //roles.forEach((r) => {
              for (const r of roles)
                if (route.permissions.includes(r)) {
                  const routeCpy = Object.assign({}, route)
                  tmpRoutes.push(routeCpy)
                  routeCpy.children = getAllowedRoutes(route.children)
                  break
                }
              // })
            }
          } else {
            if (!route.permissions) {
              tmpRoutes.push(route)
            }
            //On filtre les routes suivant les roles de l'utilisateur
            else {
              roles.forEach((r) => {
                if (route.permissions.includes(r)) {
                  tmpRoutes.push(route)
                }
              })
            }
          }
        })
        return tmpRoutes
        //Filtre des routes suivant les roles utilisateurs
      }
    } else {
      //Si utilisateur non connecté on retourne les routes sans permissions
      // const publicRoute = routes.filter((route) => {
      //   if (!route.permissions) return true
      //   else return false
      // })
      return publicRoutes
    }
  }
}
