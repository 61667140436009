import React from "react"
import { Suspense } from "react"
import LoaderSecondary from "src/components/loaders/LoaderSecondary"
import PageLayout from "../../PagesLayout"
import TourDeFranceLogo from "src/assets/images/logo/TDF.png"

//Biking general
const TourOfFranceGeneralWinners = React.lazy(() =>
  import("src/views/cycling/tdf/general/winners/Winners")
)
// const TourOfFranceGeneralDoubleChanceWinner = React.lazy(() =>
//   import("src/views/cycling/tdf/general/doubleChanceWinner/DoubleChanceWinner")
// )
const TourOfFranceGeneralPodium = React.lazy(() =>
  import("src/views/cycling/tdf/general/podium/Podium")
)
// const TourOfFranceGeneralDoubleChancePodium = React.lazy(() =>
//   import("src/views/cycling/tdf/general/doubleChancePodium/DoubleChancePodium")
// )

//biking étape
const TDFStages = React.lazy(() =>
  import("src/views/cycling/tdf/stages/stagesInformations/StagesInformations")
)
// const TourOfFranceStepWinner = React.lazy(() =>
//   import("src/views/cycling/tdf/stages/winner/Winner")
// )
// const TourOfFranceStepDoubleChanceWinner = React.lazy(() =>
//   import("src/views/cycling/tdf/stages/doubleChanceWinner/DoubleChanceWinner")
// )
// const TourOfFranceStepPodium = React.lazy(() =>
//   import("src/views/cycling/tdf/stages/podium/Podium")
// )
// const TourOfFranceStepDoubleChancePodium = React.lazy(() =>
//   import("src/views/cycling/tdf/stages/doubleChancePodium/DoubleChancePodium")
// )

//biking classement
const TourOfFranceRankBestYoung = React.lazy(() =>
  import("src/views/cycling/tdf/rank/bestYoungRanking/BestYoungRanking")
)
const TourOfFranceRankClimber = React.lazy(() =>
  import("src/views/cycling/tdf/rank/climberRanking/ClimberRanking")
)
const TourOfFranceRankPoints = React.lazy(() =>
  import("src/views/cycling/tdf/rank/pointsRanking/PointsRanking")
)
const TourOfFranceRankTeams = React.lazy(() =>
  import("src/views/cycling/tdf/rank/teamRanking/TeamRanking")
)

const TeamsBikingStandardization = React.lazy(() =>
  import("src/views/cycling/tdf/standardization/Teams")
)
const RunnersBikingStandardization = React.lazy(() =>
  import("src/views/cycling/tdf/standardization/Runners")
)

//Biking settings pages
const DiscrepanciesBiking = React.lazy(() =>
  import("src/views/cycling/tdf/settings/discrepancies/Discrepancies")
)

const ScrapingBiking = React.lazy(() =>
  import("src/views/cycling/tdf/settings/scraping/Scraping")
)

const StandardizationBiking = React.lazy(() =>
  import("src/views/cycling/tdf/settings/standardization/Standardization")
)

export const tdfRoutes = {
  name: "TDF",
  path: "/cycling/tdf",
  state: "cycling.tdf",
  sidebarProps: {
    displayText: "tdf",
    icon: (
      <img
        src={TourDeFranceLogo}
        alt="Tour de France logo"
        height={25}
        width={25}
        style={{ marginRight: "5px" }}
      />
    ),
    style: "menu2",
  },
  element: <PageLayout />,
  permissions: ["ROLE_TDF"],
  children: [
    {
      name: "TDFStages",
      path: "/cycling/tdf/stagestdf",
      state: "cycling.tdf.stagestdf",
      sidebarProps: {
        displayText: "stagesInf",
        style: "menu3",
      },
      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <TDFStages />
        </Suspense>
      ),
      permissions: ["ROLE_TDF"],
    },
    {
      name: "general",
      path: "/cycling/tdf/general",
      state: "cycling.tdf/general",
      sidebarProps: {
        displayText: "general",
        style: "menu3",
      },
      element: <PageLayout />,
      children: [
        {
          name: "TourOfFranceGeneralWinner",
          path: "/cycling/tdf/general/winner",
          state: "cycling.tdf/general.winner",
          sidebarProps: {
            displayText: "winner",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TourOfFranceGeneralWinners />
            </Suspense>
          ),
          permissions: ["ROLE_TDF"],
        },
        // {
        //   name: "TourOfFranceGeneralDoubleChanceWinner",
        //   path: "/cycling/tdf/general/winnersdc",
        //   state: "cycling.tdf/general.winnersdc",
        //   sidebarProps: {
        //     displayText: "winnersdc",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <TourOfFranceGeneralDoubleChanceWinner />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_TDF"],
        // },
        {
          name: "TourOfFranceGeneralPodium",
          path: "/cycling/tdf/general/podium",
          state: "cycling.tdf/general.podium",
          sidebarProps: {
            displayText: "podium",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TourOfFranceGeneralPodium />
            </Suspense>
          ),
          permissions: ["ROLE_TDF"],
        },
        // {
        //   name: "TourOfFranceGeneralDoubleChancePodium",
        //   path: "/cycling/tdf/general/podiumdc",
        //   state: "cycling.tdf/general.podiumdc",
        //   sidebarProps: {
        //     displayText: "podiumdc",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <TourOfFranceGeneralDoubleChancePodium />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_TDF"],
        // },
      ],
    },
    {
      name: "stages",
      path: "/cycling/tdf/stages",
      state: "cycling.tdf/stages",
      sidebarProps: {
        displayText: "stages",
        style: "menu3",
      },
      element: <PageLayout />,
      children: [
        // {
        //   name: "TourOfFranceStepWinner",
        //   path: "/cycling/tdf/stages/winners",
        //   state: "cycling.tdf/stages.winners",
        //   sidebarProps: {
        //     displayText: "winners",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <TourOfFranceStepWinner />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_TDF"],
        // },
        // {
        //   name: "TourOfFranceStepDoubleChanceWinner",
        //   path: "/cycling/tdf/stages/winnersdc",
        //   state: "cycling.tdf/stages.winnersdc",
        //   sidebarProps: {
        //     displayText: "doubleChanceWinner",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <TourOfFranceStepDoubleChanceWinner />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_TDF"],
        // },
        // {
        //   name: "TourOfFranceStepPodium",
        //   path: "/cycling/tdf/stages/podium",
        //   state: "cycling.tdf/stages.podium",
        //   sidebarProps: {
        //     displayText: "podium",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <TourOfFranceStepPodium />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_TDF"],
        // },
        // {
        //   name: "TourOfFranceStepDoubleChancePodium",
        //   path: "/cycling/tdf/stages/podiumdc",
        //   state: "cycling.tdf/stages.podiumdc",
        //   sidebarProps: {
        //     displayText: "doubleChancePodium",
        //     style: "menu4",
        //   },
        //   element: (
        //     <Suspense fallback={<LoaderSecondary />}>
        //       <TourOfFranceStepDoubleChancePodium />
        //     </Suspense>
        //   ),
        //   permissions: ["ROLE_TDF"],
        // },
      ],
    },
    {
      name: "ranking",
      path: "/cycling/tdf/ranking",
      state: "cycling.tdf.ranking",
      sidebarProps: {
        displayText: "ranking",
        style: "menu3",
      },
      element: <PageLayout />,
      children: [
        {
          name: "TourOfFranceRankBestYoung",
          path: "/cycling/tdf/ranking/best-young",
          state: "cycling.tdf.ranking.best-young",
          sidebarProps: {
            displayText: "bestYoung",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TourOfFranceRankBestYoung />
            </Suspense>
          ),
          permissions: ["ROLE_TDF"],
        },
        {
          name: "TourOfFranceRankClimber",
          path: "/cycling/tdf/ranking/best-climber",
          state: "cycling.tdf.ranking.best-climber",
          sidebarProps: {
            displayText: "bestClimber",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TourOfFranceRankClimber />
            </Suspense>
          ),
          permissions: ["ROLE_TDF"],
        },
        {
          name: "TourOfFranceRankPoints",
          path: "/cycling/tdf/ranking/best-sprinter",
          state: "cycling.tdf.ranking.best-sprinter",
          sidebarProps: {
            displayText: "bestSprinter",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TourOfFranceRankPoints />
            </Suspense>
          ),
          permissions: ["ROLE_TDF"],
        },
        {
          name: "TourOfFranceRankTeams",
          path: "/cycling/tdf/ranking/best-team",
          state: "cycling.tdf.ranking.best-team",
          sidebarProps: {
            displayText: "bestTeam",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TourOfFranceRankTeams />
            </Suspense>
          ),
          permissions: ["ROLE_TDF"],
        },
      ],
    },
    {
      name: "Standardization",
      path: "/cycling/tdf/standardization",
      state: "cycling.tdf.standardization",
      sidebarProps: {
        displayText: "standardization",
        style: "menu3",
      },
      element: <PageLayout />,
      permissions: ["ROLE_TDF_STANDARDIZATION"],

      children: [
        {
          name: "Runners",
          path: "/cycling/tdf/standardization/runners",
          state: "cycling.tdf.standardization.runners",
          sidebarProps: {
            displayText: "runners",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <RunnersBikingStandardization />
            </Suspense>
          ),
          permissions: ["ROLE_TDF_STANDARDIZATION"],
        },
        {
          name: "Teams",
          path: "/cycling/tdf/standardization/teams",
          state: "cycling.tdf.standardization.teams",
          sidebarProps: {
            displayText: "teams",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <TeamsBikingStandardization />
            </Suspense>
          ),
          permissions: ["ROLE_TDF_STANDARDIZATION"],
        },
      ],
    },
    {
      name: "Setting",
      path: "/cycling/tdf/settings",
      state: "cycling.tdf.settings",
      sidebarProps: {
        displayText: "settings",
        style: "menu3",
      },
      element: <PageLayout />,
      permissions: [
        "ROLE_TDF_STAGING_DATA",
        "ROLE_TDF_MANAGE_DISCREPANCIES",
        "ROLE_TDF_STANDARDIZATION",
      ],

      children: [
        {
          name: "Discrepancies",
          path: "/cycling/tdf/settings/discrepancies",
          state: "cycling.tdf.settings.discrepancies",
          sidebarProps: {
            displayText: "discrepancies",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <DiscrepanciesBiking />
            </Suspense>
          ),
          permissions: ["ROLE_TDF_MANAGE_DISCREPANCIES"],
        },
        {
          name: "Scraping",
          path: "/cycling/tdf/settings/scraping",
          state: "cycling.tdf.settings.scraping",
          sidebarProps: {
            displayText: "scraping",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <ScrapingBiking />
            </Suspense>
          ),
          permissions: ["ROLE_TDF_STAGING_DATA"],
        },
        {
          name: "Standardization",
          path: "/cycling/tdf/settings/standardization",
          state: "cycling.tdf.settings.standardization",
          sidebarProps: {
            displayText: "standardization",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <StandardizationBiking />
            </Suspense>
          ),
          permissions: ["ROLE_TDF_STANDARDIZATION"],
        },
      ],
    },
  ],
}
