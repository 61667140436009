import jwtDecode from "jwt-decode"
import { createContext, useState } from "react"
import {
  getFirstname,
  getLastname,
  getJwt,
  setFirstname,
  setLastname,
  removeStorage,
  setDashboardVersion,
  getDashboardVersion,
  setDarkModeEnabled,
  getDarkModeEnabled,
  setTimeZone,
  getTimeZone,
} from "../utils/helpers"

export const UserContext = createContext({
  token: "",
  login: (token) => {},
  firstnameContext: undefined,
  changeFirstname: () => {},
  lastnameContext: undefined,
  changeLastname: () => {},
  dashboardVersionContext: undefined,
  changeDashboardVersion: () => {},
  darkModeEnabledContext: undefined,
  changeDarkModeEnabled: () => {},
  timeZoneContext: undefined,
  changeTimeZone: () => {},
  isLoggedIn: false,
  logout: () => {},
})

//get token from localStorage
const userTokenStorage = getJwt()

export const UserContextProvider = (props) => {
  const [token, setToken] = useState(userTokenStorage)
  const [firstnameContext, setFirstnameContext] = useState(getFirstname())
  const [lastnameContext, setLastnameContext] = useState(getLastname())
  const [dashboardVersionContext, setDashboardVersionContext] = useState(
    getDashboardVersion()
  )
  const [darkModeEnabledContext, setDarkModeEnabledContext] = useState(
    getDarkModeEnabled()
  )
  const [timeZoneContext, setTimeZoneContext] = useState(getTimeZone)
  //const [isLoggedIn, setLoggedIn] = useState(getJwt ? true : false)
  const userIsLoggedIn = !!token

  const handleLogin = (token) => {
    //setLoggedIn(true)
    setFirstname(jwtDecode(token).firstname)
    setLastname(jwtDecode(token).lastname)
    setFirstnameContext(jwtDecode(token).firstname)
    setLastnameContext(jwtDecode(token).lastname)
    setDashboardVersion(1)
    setDashboardVersionContext(1)
    setDarkModeEnabled(false)
    setDarkModeEnabledContext(false)
    setTimeZone("Europe/Paris")
    setTimeZoneContext("Europe/Paris")
    setToken(token)
  }

  const handleLogout = () => {
    setDarkModeEnabled(false)
    setDarkModeEnabledContext(false)
    setToken(null)
    removeStorage()
  }

  const handleChangeFirstname = (firstname) => {
    setFirstnameContext(firstname)
  }

  const handleChangeLastname = (lastname) => {
    setLastnameContext(lastname)
  }

  const handleChangeDashboardVersion = (version) => {
    setDashboardVersionContext(version)
  }

  const handleChangeDarkModeEnabled = (darkMode) => {
    setDarkModeEnabledContext(darkMode)
  }

  const handleChangeTimezone = (timeZone) => {
    setTimeZoneContext(timeZone)
  }

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    firstnameContext: firstnameContext,
    lastnameContext: lastnameContext,
    dashboardVersionContext: dashboardVersionContext,
    darkModeEnabledContext: darkModeEnabledContext,
    timeZoneContext: timeZoneContext,
    changeFirstname: handleChangeFirstname,
    changeLastname: handleChangeLastname,
    changeDashboardVersion: handleChangeDashboardVersion,
    changeDarkModeEnabled: handleChangeDarkModeEnabled,
    changeTimeZone: handleChangeTimezone,
    login: handleLogin,
    logout: handleLogout,
  }

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  )
}
