import React from "react"
import { Suspense } from "react"
import LoaderSecondary from "src/components/loaders/LoaderSecondary"
import PageLayout from "../../PagesLayout"
import NBA from "src/assets/images/logo/NBALogo.png"

// Basketball pages
const Matchs = React.lazy(() =>
  import("src/views/basketball/nba/matches/Matchs")
)
const Winners = React.lazy(() =>
  import("src/views/basketball/nba/winners/Winners")
)
const ThreePoints = React.lazy(() =>
  import("src/views/basketball/nba/threePoints/ThreePoints")
)

const Par = React.lazy(() =>
  import("src/views/basketball/nba/playersPerformances/Par")
)
const Pa = React.lazy(() =>
  import("src/views/basketball/nba/playersPerformances/Pa")
)
const Ar = React.lazy(() =>
  import("src/views/basketball/nba/playersPerformances/Ar")
)
const Pr = React.lazy(() =>
  import("src/views/basketball/nba/playersPerformances/Pr")
)

const Points = React.lazy(() =>
  import("src/views/basketball/nba/playersPerformances/Points")
)
const Rebounds = React.lazy(() =>
  import("src/views/basketball/nba/playersPerformances/Rebounds")
)
const Assists = React.lazy(() =>
  import("src/views/basketball/nba/playersPerformances/Assists")
)

//Basketball settings pages
const Teams = React.lazy(() =>
  import("src/views/basketball/nba/standardization/Teams")
)
const Players = React.lazy(() =>
  import("src/views/basketball/nba/standardization/Players")
)
const Discrepancies = React.lazy(() =>
  import("src/views/basketball/nba/settings/discrepancies/Discrepancies")
)

const Scraping = React.lazy(() =>
  import("src/views/basketball/nba/settings/scraping/Scraping")
)

const Standardization = React.lazy(() =>
  import("src/views/basketball/nba/settings/standardization/Standardization")
)

export const nbaRoutes = {
  name: "NBA",
  path: "/basketball/nba",
  state: "basketball.nba",
  sidebarProps: {
    displayText: "nba",
    icon: (
      <img
        src={NBA}
        alt="NBA Logo"
        height={25}
        width={25}
        style={{ marginRight: "5px" }}
      />
    ),
    style: "menu2",
  },
  element: <PageLayout />,
  permissions: [
    "ROLE_NBA",
    "ROLE_NBA_STANDARDIZATION",
    "ROLE_NBA_MANAGE_DISCREPANCIES",
    "ROLE_NBA_STAGING_DATA",
  ],

  children: [
    {
      name: "Match",
      path: "/basketball/nba/matchs",
      state: "basketball.nba.matchs",
      sidebarProps: {
        displayText: "matchs",
        style: "menu3",
      },

      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <Matchs />
        </Suspense>
      ),
      permissions: ["ROLE_NBA"],
    },
    {
      name: "Winners",
      path: "/basketball/nba/winners",
      state: "basketball.nba.winners",
      sidebarProps: {
        displayText: "moneylines",
        style: "menu3",
      },
      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <Winners />
        </Suspense>
      ),
      permissions: ["ROLE_NBA"],
    },
    {
      name: "ThreePoints",
      path: "/basketball/nba/three-points",
      state: "basketball.nba.threePoints",
      sidebarProps: {
        displayText: "threePoints",
        style: "menu3",
      },
      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <ThreePoints />
        </Suspense>
      ),
      permissions: ["ROLE_NBA"],
    },
    {
      name: "PlayerPerfs",
      path: "/basketball/nba/playersperfs",
      state: "basketball.nba.playersperfs",
      sidebarProps: {
        displayText: "playersPerf",
        style: "menu3",
      },
      element: <PageLayout />,
      permissions: [
        "ROLE_NBA",
        "ROLE_NBA_STANDARDIZATION",
        "ROLE_NBA_MANAGE_DISCREPANCIES",
        "ROLE_NBA_STAGING_DATA",
      ],

      children: [
        {
          name: "Par",
          path: "/basketball/nba/playersperfs/par",
          state: "basketball.nba.playersperfs.par",
          sidebarProps: {
            displayText: "par",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Par />
            </Suspense>
          ),
          permissions: ["ROLE_NBA"],
        },
        {
          name: "Pa",
          path: "/basketball/nba/playersperfs/pa",
          state: "basketball.nba.playersperfs.pa",
          sidebarProps: {
            displayText: "pa",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Pa />
            </Suspense>
          ),
          permissions: ["ROLE_NBA"],
        },
        {
          name: "Ar",
          path: "/basketball/nba/playersperfs/ar",
          state: "basketball.nba.playersperfs.ar",
          sidebarProps: {
            displayText: "ar",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Ar />
            </Suspense>
          ),
          permissions: ["ROLE_NBA"],
        },
        {
          name: "Pr",
          path: "/basketball/nba/playersperfs/pr",
          state: "basketball.nba.playersperfs.pr",
          sidebarProps: {
            displayText: "pr",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Pr />
            </Suspense>
          ),
          permissions: ["ROLE_NBA"],
        },
        {
          name: "Assists",
          path: "/basketball/nba/playersperfs/assists",
          state: "basketball.nba.playersperfs.assists",
          sidebarProps: {
            displayText: "assists",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Assists />
            </Suspense>
          ),
          permissions: ["ROLE_NBA"],
        },
        {
          name: "Points",
          path: "/basketball/nba/playersperfs/points",
          state: "basketball.nba.playersperfs.points",
          sidebarProps: {
            displayText: "points",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Points />
            </Suspense>
          ),
          permissions: ["ROLE_NBA"],
        },
        {
          name: "Rebounds",
          path: "/basketball/nba/playersperfs/rebounds",
          state: "basketball.nba.playersperfs.rebounds",
          sidebarProps: {
            displayText: "rebounds",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Rebounds />
            </Suspense>
          ),
          permissions: ["ROLE_NBA"],
        },
      ],
    },
    {
      name: "Standardization",
      path: "/basketball/nba/standardization",
      state: "basketball.nba.standardization",
      sidebarProps: {
        displayText: "standardization",
        style: "menu3",
      },
      element: <PageLayout />,
      permissions: ["ROLE_NBA_STANDARDIZATION"],

      children: [
        {
          name: "Players",
          path: "/basketball/nba/standardization/players",
          state: "basketball.nba.standardization.players",
          sidebarProps: {
            displayText: "players",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Players />
            </Suspense>
          ),
          permissions: ["ROLE_NBA_STANDARDIZATION"],
        },
        {
          name: "Teams",
          path: "/basketball/nba/standardization/teams",
          state: "basketball.nba.standardization.teams",
          sidebarProps: {
            displayText: "teams",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Teams />
            </Suspense>
          ),
          permissions: ["ROLE_NBA_STANDARDIZATION"],
        },
      ],
    },
    {
      name: "Setting",
      path: "/basketball/nba/settings",
      state: "basketball.nba.settings",
      sidebarProps: {
        displayText: "settings",
        style: "menu3",
      },
      element: <PageLayout />,
      permissions: [
        "ROLE_NBA_STAGING_DATA",
        "ROLE_NBA_MANAGE_DISCREPANCIES",
        "ROLE_NBA_STANDARDIZATION",
      ],

      children: [
        {
          name: "Discrepancies",
          path: "/basketball/nba/settings/discrepancies",
          state: "basketball.nba.settings.discrepancies",
          sidebarProps: {
            displayText: "discrepancies",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Discrepancies />
            </Suspense>
          ),
          permissions: ["ROLE_NBA_MANAGE_DISCREPANCIES"],
        },
        {
          name: "Scraping",
          path: "/basketball/nba/settings/scraping",
          state: "basketball.nba.settings.scraping",
          sidebarProps: {
            displayText: "scraping",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Scraping />
            </Suspense>
          ),
          permissions: ["ROLE_NBA_STAGING_DATA"],
        },
        {
          name: "Standardization",
          path: "/basketball/nba/settings/standardization",
          state: "basketball.nba.settings.standardization",
          sidebarProps: {
            displayText: "standardization",
            style: "menu4",
          },
          element: (
            <Suspense fallback={<LoaderSecondary />}>
              <Standardization />
            </Suspense>
          ),
          permissions: ["ROLE_NBA_STANDARDIZATION"],
        },
      ],
    },
  ],
}
