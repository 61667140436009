import React from "react"
import { redirectIfUserIsLogged } from "src/utils/helpers"
import { Suspense } from "react"
import LoaderSecondary from "src/components/loaders/LoaderSecondary"
//import PublicLayout from "src/layout/public/PublicLayout"

// Pages utilisateur non authentifié
const Login = React.lazy(() => import("src/views/general/public/login/Login"))
const CreateAccount = React.lazy(() =>
  import("src/views/general/public/createAccount/CreateAccount")
)
//Vérification email
const EmailChangeValidation = React.lazy(() =>
  import("src/views/general/emailChangeValidation/EmailChangeValidation")
)
const PasswordResetValidation = React.lazy(() =>
  import(
    "src/views/general/public/passwordResetValidation/PasswordResetValidation"
  )
)
const Offline = React.lazy(() => import("src/views/general/offline/Offline"))
const PasswordReset = React.lazy(() =>
  import("src/views/general/public/passwordReset/PasswordReset")
)

export const publicRoutes =
  // {
  //   name: "Public",
  //   path: "/",
  //   element: <PublicLayout />,
  //   children:
  [
    {
      name: "Login",
      index: true,
      state: "login",
      element: <Login verifyEmail={false} />,
      //On redirige l'utilisateur s'il est connecté
      loader: redirectIfUserIsLogged,
    },
    {
      name: "Login",
      path: "/login",
      state: "login",
      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <Login verifyEmail={false} />
        </Suspense>
      ),
      loader: redirectIfUserIsLogged,
    },
    {
      name: "Offline",
      path: "/offline",
      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <Offline />
        </Suspense>
      ),
      loader: redirectIfUserIsLogged,
    },
    {
      name: "Recovery",
      path: "/recovery",
      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <PasswordReset />
        </Suspense>
      ),
      loader: redirectIfUserIsLogged,
    },
    //Page de login lorsque l'utilisateur a fait une demande de changement d'email et a cliqué sur le lien envoyé sur son ancien email. Si l'utilisateur connecté entre ce path, redirige vers la page de login
    {
      path: "/email/verify/:token",
      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <EmailChangeValidation />
        </Suspense>
      ),
      //loader: redirectIfUserIsLogged,
    },
    //Page d'activation du compte pour un utilisateur non connecté qui a recu ce lien par email après avoir été créé par un administrateur
    {
      path: "/init/account/:token",
      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <CreateAccount />
        </Suspense>
      ),
      loader: redirectIfUserIsLogged,
    },
    //Page de modification du mot de passe, lien recu sur l'email de l'utilisateur après qu'il ait fait une demande de récupération de mot de passe
    {
      path: "/reset/password/:token",
      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <PasswordResetValidation />
        </Suspense>
      ),
      loader: redirectIfUserIsLogged,
    },
  ]
//}
