import React from "react"
import { Suspense } from "react"
import LoaderSecondary from "src/components/loaders/LoaderSecondary"
import PageLayout from "../PagesLayout"
import CBiking from "src/assets/images/logo/biking.png"
import TDILogo from "src/assets/images/logo/TDI.png"
// import TDELogo from "src/assets/images/logo/TDE.png"
import { tdfRoutes } from "./tdfRoutes/tdfRoutes"
import { vueltaRoutes } from "./vueltaRoutes/vueltaRoutes"
//import { giroRoutes } from "./giroRoutes/giroRoutes"

const NotAvailableEvent = React.lazy(() =>
  import("src/views/general/notAvailableEvent/NotAvailableEvent")
)

export const cyclingRoutes = {
  name: "Cycling",
  path: "/cycling",
  state: "cycling",
  permissions: [
    "ROLE_TDF",
    "ROLE_TDF_STANDARDIZATION",
    "ROLE_TDF_MANAGE_DISCREPANCIES",
    "ROLE_TDF_STAGING_DATA",
    "ROLE_GIRO",
    "ROLE_GIRO_STAGING_DATA",
    "ROLE_GIRO_MANAGE_DISCREPANCIES",
    "ROLE_GIRO_STANDARDIZATION",
    "ROLE_VUELTA",
    "ROLE_VUELTA_STAGING_DATA",
    "ROLE_VUELTA_MANAGE_DISCREPANCIES",
    "ROLE_VUELTA_STANDARDIZATION",
  ],
  element: <PageLayout />,
  sidebarProps: {
    displayText: "cycling",
    icon: (
      <img
        src={CBiking}
        alt="biking logo"
        height={25}
        width={25}
        style={{ marginRight: "5px" }}
      />
    ),
    style: "menu1",
  },
  children: [
    tdfRoutes,
    //Décommenter en enlever objet suivant pour afficher les routes du vuelta
    vueltaRoutes,
    // {
    //   name: "vuelta",
    //   path: "/cycling/vuelta",
    //   state: "cycling.vuelta",
    //   sidebarProps: {
    //     displayText: "vuelta",
    //     icon: (
    //       <img
    //         src={TDELogo}
    //         alt="Tour d'Espagne"
    //         height={25}
    //         width={25}
    //         style={{ marginRight: "5px" }}
    //       />
    //     ),
    //     style: "menu2",
    //   },
    //   element: (
    //     <Suspense fallback={<LoaderSecondary />}>
    //       <NotAvailableEvent />
    //     </Suspense>
    //   ),
    //   permissions: ["ROLE_VUELTA"],
    // },
    //Décommenter en enlever objet suivant pour afficher les routes du vuelta
    //giroRoutes,
    {
      name: "giro",
      path: "/cycling/giro",
      state: "cycling.giro",
      sidebarProps: {
        displayText: "giro",
        icon: (
          <img
            src={TDILogo}
            alt="Tour d'Italie"
            height={25}
            width={25}
            style={{ marginRight: "5px" }}
          />
        ),
        style: "menu2",
      },
      element: (
        <Suspense fallback={<LoaderSecondary />}>
          <NotAvailableEvent />
        </Suspense>
      ),
      permissions: ["ROLE_GIRO"],
    },
  ],
}
