import i18n from "src/i18n"

//language par défaut
export const defaultLanguage = "fr"

//liste des langues gérées par l'application
export const languages = ["en", "fr"]

//Récupérer la langue sélectionnée dans le sessionStorage
//si elle fait partie des langues supportées : on retourne sa valeur
//sinon on retour la langue par défaut
export const getSelectedLanguage = () => {
  return sessionStorage.getItem("i18nextLng")
    ? sessionStorage.getItem("i18nextLng")
    : i18n.language
}
