import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"
import colorConfigs from "../configs/colorConfigs"
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined"
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined"
import SidebarItem from "./SidebarItem"
import { useSelector } from "react-redux"
import style from "./SidebarItemCollapse.module.css"
import { useTranslation } from "react-i18next"

const SidebarItemCollapse = ({ item }) => {
  const { appState } = useSelector((state) => state.appState)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation(["routes"])

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [appState, item.state])

  const level = item.sidebarProps.style

  const leveltest = level + "open"

  return item.sidebarProps ? (
    <div className={`${open ? style[leveltest] : ""}`}>
      <ListItemButton
        onClick={() => setOpen(!open)}
        sx={{
          "&: hover": {
            backgroundColor: colorConfigs.sidebar.hoverBg,
          },
          // paddingY: "12px",
          paddingX: "0px",
          color: "hsla(0,0%,100%,.6)",
        }}
      >
        {/* <ListItemIcon
          sx={{
            color: colorConfigs.sidebar.color,
          }}
        > */}
        <div className={style.listItemContent}>
          <div className={`${style[level]}`}>
            <span>{item.sidebarProps.icon}</span>
            {/* </ListItemIcon> */}
            <ListItemText
              disableTypography
              primary={
                <Typography>
                  {t(`routes.${item.sidebarProps.displayText}`)}
                </Typography>
              }
            />
          </div>
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </div>
      </ListItemButton>
      <Collapse in={open} timeout="auto">
        <List>
          {item.children?.map((route, index) =>
            route.sidebarProps ? (
              route.children ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          )}
        </List>
      </Collapse>
    </div>
  ) : null
}

export default SidebarItemCollapse
