import React, { Suspense, useContext } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./scss/style.scss"
import "./App.css"
// import MainLayout from "./layout/protected/MainLayout"
import DefaultLayout from "./layout/protected/DefaultLayout"
//import { routes } from "./routes"
import { RouterContext } from "./context/RouterContext"
import PubilcLayout from "./layout/public/PublicLayout"
import { getUserRoles } from "./utils/helpers"
import { generateRoute } from "./utils/routes/GenerateRoutes"
import ErrorPage from "./views/general/errorPage/ErrorPage"
import Loader from "./components/loaders/Loader"
import packageInfo from "../package.json"
import { CacheBuster } from "react-cache-buster/dist/CacheBuster"

function App() {
  const version = packageInfo.version
  const routes = generateRoute(useContext(RouterContext).router)
  const roles = getUserRoles()
  return (
    <CacheBuster
      currentVersion={version}
      reloadOnDowngrade={true}
      // onCacheClear={() => {
      //   window.location.reload(true);
      // }}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            {!roles ? (
              <Route path="/" element={<PubilcLayout />}>
                {routes}
              </Route>
            ) : (
              <Route path="/" element={<DefaultLayout />}>
                {routes}
              </Route>
            )}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </CacheBuster>
  )
}

export default App

// import React, { useContext } from "react"
// import { RouterProvider } from "react-router-dom"
// import "./scss/style.scss"
// import "./App.css"
// import { RouterContext } from "./context/RouterContext"
// import CacheBuster from "react-cache-buster"
// import packageInfo from "../package.json"
// import Loader from "./components/loaders/Loader"
// //import { createRouter } from "./utils/routes/createRouter"

// function App() {
//   const version = packageInfo.version
//   const isProduction = process.env.NODE_ENV === "production"
//   // ROUTER
//   //const { routes } = useContext(RouterContext)
//   //const router = createRouter(routes)
//   const { router } = useContext(RouterContext)
//   return (
//     <CacheBuster
//       currentVersion={version}
//       reloadOnDowngrade={true}
//       // onCacheClear={() => {
//       //   window.location.reload(true);
//       // }}
//       isEnabled={isProduction} //If false, the library is disabled.
//       isVerboseMode={false} //If true, the library writes verbose logs to console.
//       loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
//       metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
//     >
//       {/* <Suspense fallback={<Loader />}> */}
//       <RouterProvider router={router} fallbackElement={<Loader />} />
//       {/* </Suspense> */}
//     </CacheBuster>
//   )
// }

// export default App
