import { createContext, useState } from "react"
import { getAllowedRoutes } from "src/utils/routes/getAllowedRoutes"
//import { routes as AppRoutes } from "src/utils/routes/routes"
import { routes } from "src/utils/routes/routes"
//import { createRouter } from "src/utils/routes/createRouter"

export const RouterContext = createContext({
  //router: getAllowedRoutes(routes),
  router: "",
  handleUpdateRouter: (router) => {},
})

export const RouterContextProvider = (props) => {
  const [router, setRouter] = useState(getAllowedRoutes(routes))
  const handleUpdateRouter = (router) => {
    setRouter(router)
  }

  const contextValue = {
    router: router,
    handleUpdateRouter: handleUpdateRouter,
  }

  return (
    <RouterContext.Provider value={contextValue}>
      {props.children}
    </RouterContext.Provider>
  )
}
